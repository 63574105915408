<template>
	<div class="remand">
		<el-button type="primary" @click="toggleSelection()">主要按钮</el-button>
		<el-table border :data="remandtableData" style="width: 100%" @selection-change="SelectionChange">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column prop="date" label="计划名称" width="360">
				<template slot-scope="scope">
					<div class="plantit">
						<div class="rema-title">{{scope.row.name}}</div>
						<div class="rema-zhid">
							<div class="rema-tag">{{scope.row.asspyte ==1 ? '专业推广' : '未知'}}</div>
							<div class="ream-num">ID:{{scope.row.zhid}}</div>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="date" label="操作">
				<template slot-scope="">
					<div class="openr">
						<span>编辑</span>
						<span>复制</span>
						<span>复审</span>
					</div>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
export default {
	props:['timeValue'],
	data () {
		return {
			remandtableData:[
				{
					zhid: '1700221231231212',
					name: '鹤-断面背心-7-1-1组',
					asspyte: '1',
					assid: '1',
				},{
					zhid: '1700221231231212',
					name: '鹤-断面背心-7-1-1组',
					asspyte: '1',
					assid: '2',
				},{
					zhid: '1700221231231212',
					name: '鹤-断面背心-7-1-1组',
					asspyte: '1',
					assid: '3',
				},{
					zhid: '1700221231231212',
					name: '鹤-断面背心-7-1-1组',
					asspyte: '1',
					assid: '4',
				},{
					zhid: '1700221231231212',
					name: '鹤-断面背心-7-1-1组',
					asspyte: '1',
					assid: '5',
				},{
					zhid: '1700221231231212',
					name: '鹤-断面背心-7-1-1组',
					asspyte: '1',
					assid: '6',
				},{
					zhid: '1700221231231212',
					name: '鹤-断面背心-7-1-1组',
					asspyte: '1',
					assid: '7',
				}
			],
			SelectionData:[],//表格选中
		}
	},
	watch: {
		// 接收父组件的值（选择时间）
		timeValue: function (val) {  
			     
		}
    },
	mounted(){
		// console.log(this.timeValue)
	},

	methods: {
		SelectionChange(val){
			this.SelectionData = val
		},
		toggleSelection(){
			if(this.SelectionData.length){
				var SelectionData = []
				this.SelectionData.forEach((v,k) => {
					SelectionData.push(v.assid)
				});
				// console.log(SelectionData)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.remand{
	.plantit{
		.rema-title{
			font-weight: 500; 
			color: #333;
		}
		.rema-zhid{
			display: flex;
			margin-top: 5px;
			.rema-tag{
				padding: 1px 5px;
				background: #f5f5f5;
				margin-right: 5px;
				font-size: 12px;
			}
		}
	}
	.openr{
		display: flex;
		span{
			margin-right: 10px;
			cursor: pointer;
		}
	}
}
</style>
